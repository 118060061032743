<template>
  <div class="_page-content">
    <a-row :gutter="20">
      <a-col
        :md="5"
        :sm="24"
      >
        <deptTree @onSelect="onSelect" />
      </a-col>
      <a-col
        :md="19"
        :sm="24"
      >
        <ax-table
          ref="tableBox"
          :show-search="true"
          :searchActions='searchActions'
          :searchForm="searchForm"
          :columns="columns"
          :dataSourceApi="api.listCheck"
          :autoLoad="false"
          :sqlParams="sqlParams"
          :dataSourceParams="dataSourceParams"
          :scroll="{ y: '55vh', x: '80vw' }"
          :clickable="false"
          @pop-confirm="popConfirm"
          @action-column-click="actionColumnClick"
          @add="handleAdd"
          @export="expor"
        >
          <template #userName="{record}">
            <div
              class="table-ellipsis"
              @click="handleClick(record)"
            >
              {{record.userName}}
            </div>
          </template>
        </ax-table>
      </a-col>
    </a-row>

    <a-modal
      v-model="addShow"
      title="新增人员"
      width="1000px"
      forceRender
      @ok='addOk'
    >
      <add
        ref='add'
        :currentTable="currentTable"
        :selectTreeValue="this.treeChoice"
      ></add>
    </a-modal>

    <a-modal
      v-model="updateShow"
      title="修改"
      width="1000px"
      @ok='updateOk'
    >
      <update
        ref='update'
        :currentTable="currentTable"
      ></update>
    </a-modal>

    <a-modal
      v-model="detailShow"
      title="详情"
      width="1000px"
    >
      <div slot='footer'></div>
      <detail
        ref='detail'
        :currentTable="currentTable"
      ></detail>
    </a-modal>

    <a-modal
      v-model="displayModal"
      title="查看默认密码"
      width="35%"
      @ok='handOk'
    >
      <ax-form
        ref="formBox"
        :formBuilder="formBuilder"
      >
      </ax-form>
    </a-modal>
  </div>
</template>

<script>
import api from './api'
import add from './add.vue'
import update from './update.vue'
import detail from './detail.vue'
import deptTree from './component/deptTree.vue'
import { isEmpty, initGridFormData } from '../../../common/tools'
export default {
  name: 'asset',
  components: {
    add, update, detail, deptTree
  },
  data () {
    return {
      api,
      treeChoice: '',
      displayModal: false,
      currentTable: {},
      addShow: false,
      updateShow: false,
      detailShow: false,
      tableData: [],
      formBuilder: initGridFormData([
        { label: '账户', type: '', model: 'username', options: { disabled: true }, col: { span: 24 } },
        { label: '默认密码', type: 'input', model: 'defaultPassword', options: { disabled: true }, col: { span: 24 } }
      ], { layout: 'horizontal', labelWidth: 120 }, { col: { span: 8 } }),
      dateRangeKeys: [{ startKey: 'beginDate', endKey: 'endDate' }],
      searchForm: this.$common.initGridFormData([
        // { label: '部门', type: 'select', model: 'deptId', options: {}, formItem: {}, col: { span: 6 } },
        { label: '姓名', type: '', model: 'userName', options: {}, formItem: {}, col: { span: 6 } },
        { label: '职务', type: '', model: 'duty', options: {}, formItem: {}, col: { span: 6 } },
        { label: '职称', type: 'select', model: 'professional', options: {}, col: { span: 6 } }], { layout: 'horizontal', labelWidth: 70 }),
      pager: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      dataSourceParams: {
      },
      columns: this.$ax.tools.initColumn([
        {
          title: '姓名',
          dataIndex: 'userName',
          width: 130,
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'sex_dictText',
          ellipsis: true,
          width: 80
        },
        {
          title: '部门名称',
          dataIndex: 'deptName',
          ellipsis: true,
          width: 250
        },
        {
          title: '电话',
          dataIndex: 'phone',
          ellipsis: true,
          width: 140
        },
        {
          title: '职务',
          dataIndex: 'duty',
          ellipsis: true,
          width: 155
        },
        {
          title: '职称',
          dataIndex: 'professional',
          ellipsis: true,
          width: 80
        },
        {
          title: '编制',
          dataIndex: 'structural',
          ellipsis: true,
          width: 80
        },
        {
          title: '学位',
          dataIndex: 'degree_dictText',
          ellipsis: true,
          width: 80
        },
        {
          title: '聘用方式',
          dataIndex: 'engageWay_dictText',
          ellipsis: true,
          width: 130
        },
        {
          title: '专业',
          dataIndex: 'major',
          ellipsis: true,
          width: 250
        },
        {
          title: '电子邮件',
          dataIndex: 'email',
          ellipsis: true
        }
      ], true, {
        width: 300,
        fixed: 'right',
        actions: [
          { props: record => { return { text: '修改', name: 'update', type: '#3853db', link: true } } },
          { props: record => { return { text: '重置密码', name: 'restPwd', type: '#3853db', link: true, popDisabled: false, title: '确认要重置密码吗？' } } },
          { props: record => { return { text: '查看初始密码', name: 'initPwd', type: '#3853db', link: true } } },
          { props: record => { return { text: '删除', name: 'del', type: '#ff444a', link: true, popDisabled: false, title: '确认删除？' } } }
        ]
      }),
      searchActions: [
        { name: 'add', text: '新增人员' },
        { name: 'search', text: '查询' },
        { name: 'reset', text: '重置' },
        { name: 'export', text: '导出Excel' }
      ],
      sqlParams: {
        like: ['userName', 'duty']
      }
    }
  },
  provide () {
    return {
      changeValue: this.changeValue,
      getList: this.getList
    }
  },
  async created () {
  },
  async mounted () {
    this.getList()
    // 申请科室
    this.deptList()
    this.professional()
  },
  methods: {
    addOk () {
      this.$refs.add.submit()
    },
    updateOk () {
      this.$refs.update.submit()
    },
    async professional () {
      await api.dictData({ dicKind: 'staff_professional' }).then(res => {
        const options = res.data.map(res => { return { label: res.dicDesc, value: res.dicCode } })
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp('professional', { options: { options, allowClear: true } })
      })
    },
    async deptList () {
      await api.insideList().then(res => {
        const options = res.data.map(res => { return { label: res.deptName, value: res.id } })
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp('deptId', { options: { options, allowClear: true } })
      })
    },
    // 改变父值
    changeValue (name, value) {
      this[name] = value
    },
    onSelect (obj = {}) {
      console.log(obj, 'obj')
      this.dataSourceParams.deptId = obj.deptId
      this.treeChoice = obj.deptId
      console.log(this.$refs.add)
      this.$refs.add.setSelectTreeValue(obj)
      this.getList(obj)
    },
    getList (obj) {
      this.dataSourceParams.pageNo = 1
      this.dataSourceParams.pageSize = 20
      this.$refs.tableBox.getDataSource({ ...obj })
    },
    popConfirm ({ record, btn: { name } }) {
      if (name === 'del') {
        api.del(record.id).then(res => {
          this.getList()
        })
      }
      if (name === 'restPwd') {
        var obj = { id: record.userId }
        api.resetPassword(obj).then(res => {
          this.$message.success('重置密码成功')
        })
      }
    },
    actionColumnClick (args) {
      const { btn: { name }, record } = args
      switch (name) {
        case 'lend':
          this.lendShow = true
          this.currentTable = record
          break
        case 'add':
          this.addShow = true
          this.currentTable = {}
          break
        case 'update':
          this.updateShow = true
          this.currentTable = record
          break
        case 'detail':
          this.detailShow = true
          this.currentTable = record
          break
        case 'back':
          api.back(`?assetsId=${record.id}`).then(res => {
            this.$refs.tableBox.getDataSource()
          })
          break
        case 'restPwd':
          var obj = { id: record.userId }
          api.resetPassword(obj).then(res => {
            this.$message.success('重置密码成功')
          })
          break
        case 'initPwd':
          api.queryById(record.userId).then((res) => {
            if (isEmpty(res.data.defaultPassword)) {
              this.$message.error('用户已经修改默认密码')
            } else {
              this.displayModal = true
              this.$nextTick(() => {
                this.$refs.formBox.setFieldsValue(res.data)
              })
              // .setFieldsValue(res.data)
            }
          })
          console.log('用户点击了查看密码')
          break
      }
    },
    handOk () {
      this.displayModal = false
    },

    handleClick (record) {
      this.detailShow = true
      this.currentTable = record
    },

    // 添加用户
    handleAdd: function () {
      this.$nextTick(() => {
        this.$refs.add.form.setFieldsValue({})
      })
      if (isEmpty(this.treeChoice)) {
        this.$message.warning('请先选择左侧部门机构')
        return
      }
      this.addShow = true
    },

    async reset () {
      await this.$refs.tableBox.$children[0].$refs.searchForm.resetFields()
      this.getList()
    },
    onValueChange (value, params) {
      switch (params) {
        case 'username':
          this.dataSourceParams.username = value
          break
        case 'realname':
          this.dataSourceParams.realname = value
          break
        case 'roleId':
          this.dataSourceParams.roleId = value
          break
      }
    },
    expor () {
      const values = JSON.parse(JSON.stringify(this.searchForm.formValues))
      if (!isEmpty(values.userName)) {
        values.userName = '*' + values.userName + '*'
      }
      if (!isEmpty(values.duty)) {
        values.duty = '*' + values.duty + '*'
      }

      api.exportXls({
        fileName: '人员管理列表.xls',
        params: values
      })
    }
  }
}
</script>
